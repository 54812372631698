import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql} from "koye"
import  GlobalStyle from "./GlobalStyle"
import Header from "./header"
import "./layout.css"
import "./simple-grid.css"
import { ParallaxProvider } from 'react-scroll-parallax';
import Footer from "./footer";

const Layout = ({children}) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <GlobalStyle/>

                <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                    <ParallaxProvider>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                            <Header siteTitle={data.site.siteMetadata.title}/>

                            <main style={{width: '100%', height: '100%'}}>{children}</main>

                            <Footer/>
                        </div>
                    </ParallaxProvider>
                </div>
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
