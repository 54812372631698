import {Link} from "koye";
import React from "react";
import FooterLogo from "../images/footer_logo.png";
import Instagram from "../images/instagram_icon.png";
import CrunchBase from "../images/crunch_base_icon.png";
import MailIcon from "../images/mail_icon_white.png";
// import FooterBackground from "../images/blueprint/footer.png";
// import Image from "../components/image";
import {Image2} from "./image";

const Footer = () => (
    <footer style={{display: 'flex', flex: 1, alignItems: 'center', width: '100%', position: 'relative', textAlign: 'center'}}>
        <Image2 src={"footer.png"} style={{display: 'flex', flex: 1, marginBottom: 0}} className={"footer"}/>

        <div style={{width: '100%', height: '100%', flex: 1, display: 'flex', flexDirection: 'column', zIndex: 1, position: 'absolute', alignItems: 'center', justifyContent: 'center', top: 0, fontFamily: 'Uniform Rounded, sans-serif'}}>
            <div style={{display: 'flex', flex: 1, width: '100%', height: '100%'}}/>
            <div style={{maxWidth: 960, width: '100%', display: 'flex', flex: 1, padding: 15}} className={"footer-bottom-space"}>
                <div style={{flex: 1, display: 'flex', alignItems: 'center', width: '100%'}} className={"footer-flex"}>
                    <div style={{width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}} className={"footer-flex"}>
                        <img src={FooterLogo} width={35} height={40} className={"footer-spacing"} alt=""/>

                        <div>
                            <Link
                                to="/legal/#terms"
                                style={{
                                    color: `white`,
                                    textDecoration: `none`,
                                    marginRight: '5px'
                                }}
                            >
                                <span className={"footer-terms"} style={{color: 'white'}}>Terms</span>
                            </Link>
                            <span style={{color: 'white', opacity: 0.5, marginRight: '5px'}}>&</span>
                            <Link
                                to="/legal/#privacy"
                                style={{
                                    color: `white`,
                                    textDecoration: `none`
                                }}
                            >
                                <span className={"footer-terms"}>Privacy</span>
                            </Link>
                        </div>

                    </div>

                    <div style={{width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} className={"footer-flex"}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <a style={{display: 'flex'}} href="mailto:contact@koye.app">
                                <img src={MailIcon} className={"icon-size button button-white margin-right-icon"} alt="contact@koye.app"/>
                            </a>

                            <a style={{display: 'flex'}} href="https://www.instagram.com/koyeapp/">
                                <img src={Instagram} className={"icon-size button button-white margin-right-icon"} alt=""/>
                            </a>

                            <a style={{display: 'flex'}} href="https://www.crunchbase.com/organization/koye-corp">
                                <img src={CrunchBase} className={"icon-size button button-white"} alt=""/>
                            </a>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </footer>
);

export default Footer
