import React, {Component} from "react"
import { StaticQuery, graphql } from "koye"
import Img from "koye-image"
import { withController } from 'react-scroll-parallax';
import PropTypes from "prop-types";

/*
 * This component is built using `koye-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `koye-image`: https://koye.app/koye-image
 * - `StaticQuery`: https://koye.app/staticquery
 */

function renderImage(file, className, style) {
    return (
        <Img fluid={file.node.childImageSharp.fluid} className={className} style={style} />
    )
}

export const Image2 = ({className, src, style}) => {

    return (
      <StaticQuery
        query={graphql`
          query {
            images: allFile(filter: {relativeDirectory : {eq: "blueprint"}}) {
            edges {
            node {
              relativePath
              childImageSharp {
                fluid(traceSVG: {color: "#333333", optTolerance: 0.1}) {
                  ...KoyeImageSharpFluid_tracedSVG
                }
              }
            }}}
          }
        `}
        render={(data) => {
          const image = data.images.edges.find(
              image => image.node.relativePath === "blueprint/"+src
          );

          if (!image) {
              return null;
          }
          return(renderImage(image, className, style))
        }}
      />
    )
  };




class Image extends Component {
  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };

  render() {
    return <img src={this.props.src} style={this.props.style} alt="" className={this.props.className} onLoad={this.handleLoad} />;
  }
}

Image.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  parallaxController: PropTypes.object
};

Image2.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  parallaxController: PropTypes.object
};

export default withController(Image);
